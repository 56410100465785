export enum ContinuityType {
  ended,
  continuous,
}

export enum FeatureClauses {
  None,
  must,
  must_not,
}

export interface FeatureTypes {
  bolt: "bolt";
  weld: "weld";
  anchor: "anchoring";
  endplate: "endplate";
  finplate: "finplate";
  cleat: "cleat";
  connectingplate: "connectingplate";
  gussetplate: "gussetplate";
  platetoplate: "platetoplate";
  splice: "splice";
  stiffener: "stiffener";
  stub: "stub";
}

export interface ConnFeatureFilter {
  feature: string;
  clause: FeatureClauses;
}

export interface MemberFilter {
  continuityType: ContinuityType;
  pitch: number;
  direction: number;
  cssFormCode: string | null;
}

export interface MemberModel {
  id: number;
  memberFilter: MemberFilter;
}

export interface DesignItemFilter {
  members: MemberFilter[];
}

export interface SearchQueryModel {
  designSetId: string;
  searchQueryString: string;
}

export interface CDIFilterState {
  members: MemberModel[];
  searchQuery: SearchQueryModel | undefined;
}

export interface CDIFilterStateWithFeatureFilters {
  searchFilter: CDIFilterState;
  featureFilters?: ConnFeatureFilter[];
}
