import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {ArrowDownWhiteIcon, ConnectionAppLogo, DownloadArrowDown} from "../../assets/icons";
import {addToast} from "../../services/toastsSlice";
import {UserInformations} from "../../types/User";
import {useParams} from "react-router-dom";
import {useLazyFetchIdeaConPublicUrlQuery} from "../../services/clApiSlice";
import {ConDesignItemWithPicture} from "../../types/ConDesignItem";
import {useAppDispatch} from "../../app/hooks";

interface OpenInDesktopButtonProps {
    version: number;
    userInformations: UserInformations | undefined;
    designItem: ConDesignItemWithPicture | undefined;
    setProjectDownloadsExceeded: (exceeded: boolean) => void;
    setGenerateUrlErrored: (error: boolean) => void;
}

const OpenInDesktopButton = ({
                                 version,
                                 userInformations,
                                 designItem,
                                 setProjectDownloadsExceeded,
                                 setGenerateUrlErrored,
                             }: OpenInDesktopButtonProps) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const {itemId} = useParams();
    const toggleDropdown = () => setIsOpen(!isOpen);

    const [fetchIdeaConUrlTrigger] = useLazyFetchIdeaConPublicUrlQuery();

    const openDesktopAppHandler = async () => {
        if (itemId) {
            try {
                const ideaConUrl = await fetchIdeaConUrlTrigger({
                    itemId,
                    loggingPurpose: "OpenInDesktop"
                }, false).unwrap();

                const desktopAppWindow = window.open(
                    `ideacon:${ideaConUrl}`,
                    "connectWindow",
                    "width=600,height=400",
                );

                const closeWindow = () => {
                    if (desktopAppWindow) {
                        desktopAppWindow.close();
                    }
                };

                const handlePermission = () => {
                    closeWindow();
                };

                window.addEventListener("beforeunload", handlePermission);

                if (userInformations?.userIds.licenseId && userInformations.userIds.userId) {
                    window.dataLayer.push({
                        "event": "cl_open_to_desktop_initiated",
                        "license_id": userInformations.userIds.licenseId,
                        "user_id": userInformations.userIds.userId,
                        "con_design_item_id": designItem?.designItem.conDesignItemId,
                        "con_design_set_id": designItem?.designItem.conDesignSetId,
                    });
                }

                const message = (
                    <FormattedMessage
                        id="OpenInDesktop_toast_message"
                        defaultMessage="Attempting to open desktop application."
                    />
                );
                const duration = 5000;

                dispatch(addToast({message, duration}));

                setIsOpen(false);
            } catch (error: any) {
                if (error?.status === 429) {
                    setProjectDownloadsExceeded(true);
                } else {
                    setGenerateUrlErrored(true);
                }
            }
        }
    };

    const downloadHandler = async () => {
        if (itemId) {
            try {
                const message = (
                    <FormattedMessage
                        id="DownloadIdeaCon_toast_message"
                        defaultMessage="Attempting to download IdeaCon file."
                    />
                );
                const duration = 5000;

                dispatch(addToast({message, duration}));

                const ideaConUrl = await fetchIdeaConUrlTrigger({
                    itemId,
                    loggingPurpose: "ItemDownload"
                }, false).unwrap();

                const response = await fetch(ideaConUrl);
                const blob = await response.blob();
                const downloadUrl = URL.createObjectURL(new Blob([blob], {type: blob.type}));

                const filename = `${itemId}.ideacon`;

                if (userInformations?.userIds.licenseId && userInformations.userIds.userId) {
                    window.dataLayer.push({
                        "event": "cl_download_item_initiated",
                        "license_id": userInformations.userIds.licenseId,
                        "user_id": userInformations.userIds.userId,
                        "con_design_item_id": designItem?.designItem.conDesignItemId,
                        "con_design_set_id": designItem?.designItem.conDesignSetId,
                    });
                }


                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);


            } catch (error: any) {
                if (error?.status === 429) {
                    setProjectDownloadsExceeded(true);
                } else {
                    setGenerateUrlErrored(true);
                }
                dispatch(addToast({message: "Download failed", duration: 5000}));
            }
        }
    };

    return (
        <>
            {version && version >= 6 && (
                <div className="openInDesktop-dropdown">
                    <button className="openInDesktop-dropbtn" onClick={toggleDropdown}>
                        <span className="dropbtn-span">
                            <FormattedMessage id="OpenInDesktop" defaultMessage="Open in Desktop"/>
                        </span>
                        <div className="dropbtn-arrow">
                            <ArrowDownWhiteIcon/>
                        </div>
                    </button>
                    {isOpen && (
                        <div className="openInDesktop-dropdown-content">
                            <button onClick={openDesktopAppHandler}>
                                <ConnectionAppLogo/>
                                <span>Open in Desktop </span>
                            </button>
                            <button onClick={downloadHandler}>
                                <DownloadArrowDown/>
                                <span>Download</span>
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default OpenInDesktopButton;
